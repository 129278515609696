import { useBooks } from "../contexts/BookContext";

import Card from "../components/Card";

const Books = () => {
  const { books, isLoading } = useBooks();

  return (
    <>
      <h2 className="page-title">{`林の読んだ本 ${books.length}冊`}</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="cards">
          {books.map((book) => (
            <Card key={book._id} book={book} />
          ))}
        </div>
      )}
    </>
  );
};

export default Books;
